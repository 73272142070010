.first{
    margin:5px 10px;
    padding: 0 20px;
 
  
    cursor: pointer;
    overflow: hidden;
    transition: all 250ms cubic-bezier(0.25, 0.46, 0.45, 0.94);   
}

.first :hover {
    transform: scale(1.05);
}